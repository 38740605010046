<template>
	<div>
		<el-input v-model="pwd" placeholder="请输入新密码"></el-input>
		<div class="btnArea">
			<el-button size="small" @click="handleChangeClose">关 闭</el-button>
			<el-button size="small" type="primary" @click="handleChangeConfirm">确 定</el-button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'resetPwd',
	props: {
		selectdata: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			pwd: ''
		};
	},
	methods: {
		handleChangeClose() {
			this.$emit('schange', false);
		},
		async handleChangeConfirm() {
			if(!this.pwd) return this.$message.warning('请输入密码！');
			this.$confirm('确定要重置密码吗?', '信息', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				await this.$axios('POST', `/sys/yunke/mgr/updatePassword`,{
					id:this.selectdata.id,
					password:this.pwd
				},true);
				this.$message.success('密码重置成功！');
				this.$emit('fchange', false);
			});
		}
	}
};
</script>

<style></style>
